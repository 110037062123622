import * as React from "react";
import Layout from "./Layout";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useState, useEffect } from "react";
import {
  BannerConstants,
  VisualConstants,
} from "../constants/strapiComponentConstants";

import BannerFeatured from "./molecules/banners/BannerFeatured";
import BannerCollections from "./molecules/banners/BannerCollections";
import BannerDiscord from "./molecules/banners/BannerDiscord";
import BannerGeneric from "./molecules/banners/BannerGeneric";
import BannerMarketplace from "./molecules/banners/BannerMarketplace";
import BannerFeaturedDrops from "./molecules/banners/BannerFeaturedDrops";
import BannerBlog from "./molecules/banners/BannerBlog";

import VideoPlayer from "./organisms/VideoPlayer";
import HomepageSlider from "./organisms/HomepageSlider";
import urlConstants from "../constants/urlConstants.js";

import TrendingArticles from "./organisms/TrendingArticles";
import Container from "@mui/material/Container";

import VideoEmbed from "./organisms/VideoEmbed";

import { Helmet } from "react-helmet";

const { themePaths, themePageUrlPaths } = require("../../paths.js");
const apiService = require("../services/apiService.js");

const MetalPage = ({ pageContext }) => {
  const [dropList, setDropList] = useState([]);
  const [serverTime, setServerTime] = useState("");

  useEffect(() => {
    apiService.getter(urlConstants.getLatestDropsUrl).then((response) => {
      response ? setDropList(response.data.drop) : setDropList([]);
      response ? setServerTime(response.data.systemNow) : setServerTime("");
    });
  }, []);

  const bannerData = {
    title: "Latest | NFT Drops",
    url: "marketplace/drops",
    button_text: "View All Available Drops",
  };

  const handleLoadMoreArticles = () => {
    navigate(`/${themePaths.blog}${themePageUrlPaths.allBlogs}`);
  };

  //Update blocks in gatsby-node.js otherwise absent block will throw an error
  const {
    strapiHomepage,
    strapiBannerFeatured,
    strapiBannerCollection,
    strapiBannerDiscord,
    strapiBannerShop,
    strapiBannerMarketplace,
    allStrapiHomeSlider,
    strapiHomeVideo,
    strapiBannerBlog,
    allStrapiBlogArticle,
    strapiVideoEmbedded,
  } = useStaticQuery(graphql`
    query {
      strapiHomeVideo {
        video {
          url
        }
      }
      strapiVideoEmbedded {
        Type
        url
      }
      strapiHomepage {
        title
        seo {
          metaTitle
          metaDescription
        }
      }
      strapiBannerFeatured {
        image {
          title
          image {
            url
          }
        }
        title
        button_text
        url
      }
      strapiBannerCollection {
        step_a_header
        step_a_text
        step_b_header
        step_b_text
        step_c_header
        step_c_text
        title
      }
      strapiBannerDiscord {
        image {
          url
        }
        title
        subtext
        url
        logo {
          url
        }
      }
      strapiBannerShop {
        image {
          url
        }
        title
        subtext
        button_text
        url
      }
      strapiBannerMarketplace {
        image {
          url
        }
        title
        subtext
        button_text
        url
        subtextb
        logo {
          url
        }
      }
      strapiBannerBlog {
        image {
          url
        }
        logo {
          url
        }
        title
        subtext
        url
      }
      allStrapiHomeSlider(sort: { fields: id, order: DESC }, limit: 5) {
        nodes {
          id
          button_text
          description
          main_header
          top_header
          url
          media {
            url
          }
        }
      }
      allStrapiBlogArticle(
        filter: { publishedAt: { ne: null } }
        sort: { order: DESC, fields: updatedAt }
        limit: 4
      ) {
        nodes {
          id
          createdAt
          description
          featured_image {
            url
          }
          title
          slug
          article {
            data {
              article
            }
          }
          author {
            strapi_id
            username
            image {
              url
            }
          }
          blog_category {
            name
            slug
          }
        }
      }
    }
  `);
  return (
    <Layout homepage={true}>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaTitle}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaDescription}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaTitle}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaDescription}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaTitle}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaTitle}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaDescription}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${strapiHomepage.seo.metaDescription}`}</title>
      </Helmet>

      {pageContext.blockElements.map((element, index) => {
        switch (element.__component) {
          case BannerConstants.featuredItems:
            return element.show ? (
              <BannerFeatured key={index} bannerData={strapiBannerFeatured} />
            ) : (
              false
            );
          case BannerConstants.nftDrops:
            return element.show ? (
              <BannerFeaturedDrops
                key={index}
                bannerData={bannerData}
                drops={dropList}
                serverTime={serverTime}
              />
            ) : (
              false
            );
          case BannerConstants.joinCommunity:
            return element.show ? (
              <BannerDiscord key={index} bannerData={strapiBannerDiscord} />
            ) : (
              false
            );
          case BannerConstants.marketplace:
            return element.show ? (
              <BannerMarketplace
                key={index}
                bannerData={strapiBannerMarketplace}
              />
            ) : (
              false
            );
          case BannerConstants.shop:
            return element.show ? (
              <BannerGeneric
                key={index}
                bannerData={strapiBannerShop}
                className="lg:h-96"
              />
            ) : (
              false
            );
          case BannerConstants.startCollection:
            return element.show ? ( //strapiBannerCollection
              <BannerCollections
                key={index}
                bannerData={strapiBannerCollection}
              />
            ) : (
              false
            );
          case BannerConstants.slider:
            return element.showbanner ? (
              <HomepageSlider key={index} sliderData={allStrapiHomeSlider} />
            ) : (
              false
            );
          case BannerConstants.blog:
            return element.show ? (
              <BannerBlog
                key={index}
                bannerData={strapiBannerBlog}
                useAssetUrl={false}
                useLink={true}
              />
            ) : (
              false
            );
          case VisualConstants.video:
            return element.show ? (
              <VideoPlayer key={index} video={strapiHomeVideo.video} />
            ) : (
              false
            );
          case BannerConstants.videoEmbed:
            return element.show ? (
              <VideoEmbed key={index} videoData={strapiVideoEmbedded} />
            ) : (
              false
            );
          case BannerConstants.bannerArticle:
            return element.show ? (
              <Container maxWidth="xl" className="mt-24 mb-24" key={index}>
                <div className="xl:flex xl:w-full xl:h-full xl:px-32 pxl:y-10 xl:items-start xl:justify-between">
                  <TrendingArticles
                    blog_articles={allStrapiBlogArticle.nodes}
                    darkmode={false}
                    loadMoreArticles={handleLoadMoreArticles}
                  />
                </div>
              </Container>
            ) : (
              false
            );
          default:
            return false;
        }
      })}
    </Layout>
  );
};

export default MetalPage;
