import React from "react";
import TwoColorHeader from "../../atoms/TwoColorHeader";
import Button from "../../atoms/Button";
import { Link } from "gatsby";

import { setBlank } from "../../../services/helpers";

const BannerGeneric = ({ bannerData, className = '' }) => {
  return (
    <div className="bg-bgPrimary">
      <div
        className={`pt-24 pb-16 bg-cover bg-center ${className}`}
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${process.env.GATSBY_ASSET_URL}${bannerData.image.url})`,
        }}
      >
        <div className="flex flex-col justify-center items-center gap-y-8">
          <TwoColorHeader
            title={bannerData.title}
            colorClassB="text-primary"
            whiteCount={1}
          />

          <div className="text-center w-3/4">
            {bannerData.subtext}
          </div>

          <Link
            to={bannerData.url}
            target={setBlank(bannerData.url)}
          >
            <Button
              className="bg-primary pt-2 pb-2 pl-4 pr-4 text-sm flex-none"
              children={bannerData.button_text}
              arrow={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BannerGeneric;
