import React from "react";
import HomepageSliderCard from "../molecules/HomepageSliderCard";
import Carousel from "react-material-ui-carousel";
import CircleIcon from "@mui/icons-material/Circle";
import showMoreBtn from "../../assets/images/showMoreBtn.svg";
import useWindowSize from "../../hooks/useWindowSize";

const HomepageSlider = ({ sliderData }) => {
  const { height: windowHeight } = useWindowSize();

  const doScroll = () => {
    window.scrollTo({
      top: windowHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative">
      <Carousel
        swipe={true}
        height={windowHeight < 1080 ? windowHeight : 1080}
        indicators={true}
        stopAutoPlayOnHover={false}
        navButtonsAlwaysInvisible={true}
        fullHeightHover={false}
        IndicatorIcon={<CircleIcon style={{ fontSize: "10px" }} />}
        indicatorIconButtonProps={{
          style: {
            color: "rgba(255, 255, 255, 0.2)",
            padding: "0 6px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#E02424",
          },
        }}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: "2rem",
            zIndex: "100",
          },
        }}
      >
        {sliderData.nodes.map((slide) => {
          return <HomepageSliderCard key={slide.id} sliderData={slide} />;
        })}
        {/* <HomepageSliderCard sliderData={sliderData.nodes[0]} /> */}
      </Carousel>
      <button
        onClick={doScroll}
        className="absolute bottom-[4rem] left-1/2 -translate-x-1/2 z-[200] w-10 hover:text-primary"
      >
        <img src={showMoreBtn} alt="down button" />
      </button>
    </div>
  );
};

export default HomepageSlider;
