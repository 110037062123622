import React from "react";
import TwoColorHeader from "../../atoms/TwoColorHeader";
import Button from "../../atoms/Button";
import { Link } from "gatsby";

import { setBlank } from "../../../services/helpers";

const BannerFeatured = ({ bannerData, className = "" }) => {
  return (
    <div className={`${className} lg:h-96`}>
      <div className="pt-20 pb-16">
        <div className="flex flex-col justify-center items-center gap-y-4">
          <TwoColorHeader
            title={bannerData.title}
            colorClassB="text-primary"
            whiteCount={1}
          />
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-2 sm:space-y-0 lg:grid-cols-5 lg:gap-x-8  flex flex-col items-center">
            {bannerData.image.slice(0, 6).map((element, key) => (
              <li key={key}>
                <div className="flex justify-center">
                  <img
                    src={process.env.GATSBY_ASSET_URL + element.image.url}
                    alt={element.image.title}
                  />
                </div>
              </li>
            ))}
          </ul>
          <Link to={bannerData.url} target={setBlank(bannerData.url)}>
            <Button
              className="bg-primary pt-2 pb-2 pl-4 pr-4 text-sm flex-none"
              children={bannerData.button_text}
              arrow={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BannerFeatured;
