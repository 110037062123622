import React, { useState } from "react";
import PlayImage from "../../assets/images/PLAY.svg";

import { useRef } from "react";

const VideoPlayer = ({ video }) => {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleVideoPlayback = () => {
    if (!playing) {
      videoRef.current.play();
      setPlaying(true);
      return;
    }
    videoRef.current.pause();
    setPlaying(false);
  };

  return (
    <>
      <div className="relative w-full h-[400px] md:h-[500px] lg:h-[700px] overflow-hidden my-2">
        <video
          ref={videoRef}
          muted
          loop
          className="w-full h-full object-cover"
          src={`${video.url}`}
        />

        <div
          role="button"
          onKeyDown={() => toggleVideoPlayback()}
          tabIndex={0}
          className="absolute w-full h-full z-50 top-0 left-0 cursor-pointer flex justify-center items-center"
          onClick={() => toggleVideoPlayback()}
        >
          {!playing && (
            <img
              className="w-[100px] md:w-[200px] select-none"
              alt="Video"
              src={PlayImage}
            />
          )}
        </div>
      </div>

      {
        // <div className="timecontrols">
        //    <p className="controlsTime">1:02</p>
        //    <div className="time_progressbarContainer">
        //      <div style={{ width: "40%" }} className="time_progressBar"></div>
        //    </div>
        //    <p className="controlsTime">2:05</p>
        // </div>
      }
    </>
  );
};

export default VideoPlayer;
