import * as React from "react";

export default function VideoEmbed({ videoData }) {
  return (
    <div
      className="my-2"
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        height: "0",
        overflow: "hidden",
      }}
    >
      <object
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        data={videoData.url}
        aria-label="videoEmbed"
      ></object>
    </div>
  );
}
