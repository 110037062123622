import { Link } from "gatsby";
import React from "react";
import Button from "../atoms/Button";

const HomepageSliderCard = ({ sliderData }) => {
  return (
    <div>
      <div
        className="relative w-full max-h-[1080px] h-screen bg-cover bg-center flex flex-col justify-center items-start md:p-[87px] sm:p-12 p-4"
        style={{
          backgroundImage: `url(${process.env.GATSBY_ASSET_URL}${sliderData.media.url})`,
        }}
      >
        <div className="flex flex-col items-start mx-auto md:mx-0 mt-20">
          <h3 className="bg-bgPrimary bg-opacity-50 backdrop-blur-md py-3 px-8 text-primary font-bold text-2xl">
            {sliderData.top_header}
          </h3>
          <div className="sm:p-8 p-4 bg-bgPrimary bg-opacity-50 backdrop-blur-md ">
            <div className="mb-8 sm:w-[500px]">
              <h1 className="sm:text-5xl text-3xl font-extrabold mb-4">
                {sliderData.main_header}
              </h1>
              <div
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  height: "72px",
                }}
              >
                {sliderData.description}
              </div>
            </div>
            <Link to={sliderData.url}>
              <Button className="px-10">{sliderData.button_text}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageSliderCard;
